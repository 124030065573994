<template>
  <div>
    <!-- <ArticleCard 
      v-for="article in articles" 
      :key="article.id" 
      :article="article"
    /> -->
    <SpaceExploration />
  </div>
</template>

<script>
import ArticleCard from './ArticleCard.vue'
import SpaceExploration from './articles/SpaceExploration.vue'
export default {
  name: 'Main',
  components: {
    ArticleCard,
    SpaceExploration
  },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: 'Space Exploration',
          excerpt: 'Discovering the wonders of our universe and beyond.',
          // image: require('@/assets/images/cosmic-exploration.jpg'),
          slug: 'space-exploration'
        },
        {
          id: 2,
          title: 'Placeholder 2',
          excerpt: 'This is a placeholder article.',
          image: 'https://picsum.photos/400/300?random=2',
          slug: 'placeholder-2'
        },
        {
          id: 3,
          title: 'Placeholder 3',
          excerpt: 'This is another placeholder article.',
          image: 'https://picsum.photos/400/300?random=3',
          slug: 'placeholder-3'
        },
        {
          id: 4,
          title: 'Placeholder 4',
          excerpt: 'This is another placeholder article.',
          image: 'https://picsum.photos/400/300?random=3',
          slug: 'placeholder-3'
        }
      ]
    }
  }
}
</script>

<style scoped>
.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}
</style> 