import { render, staticRenderFns } from "./Placeholder2.vue?vue&type=template&id=60f73464&scoped=true"
import script from "./Placeholder2.vue?vue&type=script&lang=js"
export * from "./Placeholder2.vue?vue&type=script&lang=js"
import style0 from "./Placeholder2.vue?vue&type=style&index=0&id=60f73464&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f73464",
  null
  
)

export default component.exports