<template>
  <div class="article-card" @click="navigateToArticle">
    <img :src="article.image" :alt="article.title" class="article-image">
    <h2>{{ article.title }}</h2>
    <p class="article-excerpt">{{ article.excerpt }}</p>
  </div>
</template>

<script>
export default {
  name: 'Article',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  methods: {
    navigateToArticle() {
      this.$router.push(`/article/${this.article.slug}`)
    }
  }
}
</script>

<style scoped>
.article-card {
  max-width: 400px;
  margin: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.article-card:hover {
  transform: translateY(-5px);
}

.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.article-excerpt {
  line-height: 1.6;
  color: #666;
}
</style> 