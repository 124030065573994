<template>
  <div class="article-container">
    <img src="https://picsum.photos/400/300?random=2" alt="Placeholder 2" class="article-image">
    <h1>Placeholder 2</h1>
    <div class="article-content">
      <p>This is a placeholder article.</p>
      <p>Full article content here... This section would contain the content for placeholder 2.</p>
      <!-- Add more content sections as needed -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Placeholder2'
}
</script>

<style scoped>
.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.article-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.article-content {
  line-height: 1.6;
  color: #333;
}
</style> 