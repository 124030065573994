<template>
  <div>
    <div class="scene-container" ref="container"></div>

    <!-- Logo -->
    <img src="@/assets/axisStudioLogo_White.png" alt="Axis Studio" class="logo">
    
    <!-- Navigation -->
    <nav class="nav-menu" :class="{ 'mobile': isMobile }">
      <a href="#" class="nav-item">PAST MISSIONS</a>
      <a href="#" class="nav-item active">CURRENT MISSIONS</a>
      <a href="#" class="nav-item">FUTURE MISSIONS</a>
    </nav>

    <!-- Desktop Content -->
    <div v-if="!isMobile" class="content-container" @scroll="handleContentScroll">
      <div class="header-content">
        <h1 class="title">Voyager</h1>
        <p class="description">
          Voyager 1 and Voyager 2 are currently the furthest spacecraft from earth. They were 
          both launched in summer of 1977 taking advantage of a rare planetary alignment 
          that would enable them to get first ever images of several planets, their moons, 
          as well as slingshot out of the solar system.
        </p>
        
        <div class="milestones">
          <div v-for="(milestone, index) in milestones" 
               :key="index" 
               class="milestone"
               :style="{ 
                position: 'absolute',
                top: `${milestone.scrollPosition * 100}%`
               }">
            <div class="milestone-date">{{ formatDate(milestone.date) }}</div>
            <div class="milestone-content">{{ milestone.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Content -->
    <div v-else class="content-container mobile" @scroll="handleContentScroll">
      <div class="header-content">
        <h1 class="title">Voyager</h1>
        <p class="description">
          Voyager 1 and Voyager 2 are currently the furthest spacecraft from earth. They were 
          both launched in summer of 1977 taking advantage of a rare planetary alignment 
          that would enable them to get first ever images of several planets, their moons, 
          as well as slingshot out of the solar system.
        </p>
        
        <div class="milestones">
          <div v-for="(milestone, index) in milestones" 
               :key="index" 
               class="milestone"
               :style="{ 
                position: 'absolute',
                top: `${milestone.scrollPosition * 100}%`
               }">
            <div class="milestone-date">{{ formatDate(milestone.date) }}</div>
            <div class="milestone-content">{{ milestone.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Timeline -->
    <div class="timeline" :class="{ 'mobile': isMobile }">
      <div class="timeline-years">
        <template v-for="(year, index) in timelineYears">
          <div 
            :key="`year-${year}-${index}`"
            class="timeline-marker year" 
            :class="{ active: isCurrentYear(year) }"
            @click="scrollToYear(year)"
          >
            — {{ year }}
          </div>
          <div 
            v-if="index < timelineYears.length - 1"
            :key="`dash-${year}-${index}`"
            class="timeline-marker year empty-year"
          >
            —
          </div>
        </template>
      </div>
    </div>

    <div class="scroll-container" ref="scrollContainer">
      <div class="scroll-debug">Current Date: {{ currentDate }}</div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import earthTexture from '@/assets/textures/earth.jpg';
import marsTexture from '@/assets/textures/mars.jpg';
import sunTexture from '@/assets/textures/sun.jpg';
import starsTexture from '@/assets/textures/stars.jpg';
import cloudsTexture from '@/assets/textures/clouds.jpg';
import moonTexture from '@/assets/textures/moon.jpg';
import jupiterTexture from '@/assets/textures/jupiter.jpg';
import mercuryTexture from '@/assets/textures/mercury.jpg';
import neptuneTexture from '@/assets/textures/neptune.jpg';
import saturnTexture from '@/assets/textures/saturn.jpg';
import uranusTexture from '@/assets/textures/uranus.jpg';
import venusTexture from '@/assets/textures/venus.jpg';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ref, onMounted } from 'vue'
import { CatmullRomCurve3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import axisStudioLogo from '@/assets/axisStudioLogo_White.png';

export default {
  name: 'SpaceExploration',
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      earth: null,
      mars: null,
      sun: null,
      scrollPercent: 0,
      targetCameraPosition: new THREE.Vector3(),
      centerPoint: new THREE.Vector3(0, 0, 0),
      composer: null,
      bloomPass: null,
      moonPivot: null,
      moon: null,
      mercury: null,
      venus: null,
      jupiter: null,
      saturn: null,
      uranus: null,
      neptune: null,
      trajectoryData: [],
      currentDataIndex: 0,
      isPlaying: false,
      animationInterval: null,
      timelineYears: [],
      currentDate: '',
      voyager: ref(null),
      cameraKeyframes: [
        {
          year: 1977,
          position: {x: 0.739, y: 0.323, z: 3.028},
          lookAt: { x: 0.503, y: 0.22, z: 2.061}
        },
        // {
        //   year: 1981,
        //   position: {x: 11.69, y: -9.29, z: -10.71},
        //   lookAt: {x: 5.33, y: -4.23, z: -4.88}   // Looking at the sun
        // }
      ],
      objectsize: {
        sun: 0.25,     // Reduced from 0.3
        mercury: 0.025, // Increased from 0.02
        venus: 0.035,   // Increased from 0.03
        earth: 0.035,   // Increased from 0.03
        moon: 0.01,     // Increased from 0.008
        mars: 0.03,     // Increased from 0.025
        jupiter: 0.12,  // Increased from 0.1
        saturn: 0.1,    // Increased from 0.08
        uranus: 0.05,   // Increased from 0.04
        neptune: 0.05,  // Increased from 0.04
        voyager: 0.012, // Increased from 0.01
      },
      controls: null,
      isDestroyed: false,
      loadedTextures: new Map(),
      isScrolled: false,
      scrollTimeout: null,
      keyboardHandler: null,
      debugFreeCam: false,
      planetTargets: {
        mercury: new THREE.Vector3(),
        venus: new THREE.Vector3(),
        earth: new THREE.Vector3(),
        mars: new THREE.Vector3(),
        jupiter: new THREE.Vector3(),
        saturn: new THREE.Vector3(),
        uranus: new THREE.Vector3(),
        neptune: new THREE.Vector3(),
        voyager1: new THREE.Vector3()
      },
      lerpFactor: 0.1, // Adjust this value to control smoothness (0.05-0.15 works well)
      userHasAdjustedCamera: false, // Track if user has manually adjusted the view
      json: null, // Store the original JSON data
      milestones: [
        {
          date: '1977-09-05',
          content: 'Voyager 1 was launched from Cape Canaveral, Florida, on a faster trajectory than its twin, Voyager 2. It was designed for a five-year mission to study Jupiter and Saturn, but its success extended its mission to interstellar space.',
          scrollPosition: 0.0337 // Start of scroll
        },
        {
          date: '1979-03-05',
          content: 'Voyager 1 made its closest approach to Jupiter, capturing detailed images of Jupiter\'s atmosphere, including the Great Red Spot. It discovered active volcanoes on Io, the first time volcanic activity had been observed beyond Earth. The spacecraft also mapped Jupiter\'s radiation belts and magnetic field.',
          scrollPosition: 0.0593 // 15% through scroll
        },
        {
          date: '1980-11-12',
          content: 'Voyager 1 made its closest approach to Saturn, providing the first detailed study of Saturn\'s atmosphere, rings, and moons. A close flyby of Titan forced Voyager 1 into a trajectory that sent it out of the ecliptic plane.',
          scrollPosition: 0.1224 // 30% through scroll
        },
        {
          date: '1989-01-01',
          content: 'Voyager 1\'s mission was officially redesignated as the Voyager Interstellar Mission (VIM) after completing its planetary studies.',
          scrollPosition: 0.4330 // 45% through scroll
        },
        {
          date: '2004-12-01',
          content: 'Voyager 1 crossed the termination shock at 94 AU, entering the heliosheath, where the solar wind slows and interacts with interstellar space.',
          scrollPosition: 1 // 60% through scroll
        },
        // {
        //   date: '2012-08-25',
        //   content: 'Voyager 1 became the first human-made object to enter interstellar space at a distance of 122 AU from the Sun. Scientists confirmed the transition based on plasma wave oscillations and an increase in cosmic ray density.',
        //   scrollPosition: 0.75 // 75% through scroll
        // },
        // {
        //   date: '2023-01-01',
        //   content: 'Voyager 1 continues its journey through interstellar space, transmitting data about plasma waves, cosmic rays, and magnetic fields. It is expected to remain operational until the mid-2030s.',
        //   scrollPosition: 0.9 // 90% through scroll
        // }
      ],
      currentScrollPosition: 0,
      contentScrolling: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    activeTimelineYear() {
      if (!this.currentDate) return this.timelineYears[0];
      
      const currentYear = new Date(this.currentDate).getFullYear();
      
      // Find the closest year in our timeline that's less than or equal to the current year
      return this.timelineYears.reduce((closest, year) => {
        if (year <= currentYear && (closest === null || year > closest)) {
          return year;
        }
        return closest;
      }, null) || this.timelineYears[0];
    }
  },
  mounted() {
    this.initScene().then(() => {
      this.animate();
      window.addEventListener('scroll', this.handleScroll);
      this.loadTrajectoryData();
      return new Promise(resolve => setTimeout(resolve, 100));
    }).then(() => {
      if (this.camera && !this.isDestroyed) {
        this.setupKeyboardListeners();
      }
    });
  },
  beforeDestroy() {
    this.isDestroyed = true;
    // Cleanup
    if (this.renderer) {
      this.renderer.dispose();
    }
    if (this.composer) {
      this.composer.dispose();
    }
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.onWindowResize);
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }
    if (this.controls) {
      this.controls.dispose();
    }

    // Dispose of geometries and materials
    const disposeObject = (obj) => {
      if (!obj) return;
      
      if (obj.geometry) {
        obj.geometry.dispose();
      }

      if (obj.material) {
        if (Array.isArray(obj.material)) {
          obj.material.forEach(material => {
            if (material.map) material.map.dispose();
            material.dispose();
          });
        } else {
          if (obj.material.map) obj.material.map.dispose();
          obj.material.dispose();
        }
      }

      if (obj.children) {
        obj.children.forEach(child => disposeObject(child));
      }
    };

    // Clean up all major objects
    [
      this.earth, 
      this.mars, 
      this.sun, 
      this.moon, 
      this.mercury, 
      this.venus,
      this.jupiter,
      this.saturn,
      this.uranus,
      this.neptune,
      this.voyager,
      this.clouds
    ].forEach(obj => disposeObject(obj));

    // Clear scene
    while(this.scene.children.length > 0) { 
      this.scene.remove(this.scene.children[0]); 
    }

    // Dispose of scene
    this.scene = null;
    this.camera = null;

    // Clear any textures from memory
    THREE.Cache.clear();

    // Remove keyboard listener
    if (this.keyboardHandler) {
      window.removeEventListener('keydown', this.keyboardHandler);
      this.keyboardHandler = null;
    }
  },
  methods: {
    async initScene() {
      // Create scene
      this.scene = new THREE.Scene();

      // Add stars background
      const textureLoader = new THREE.TextureLoader();
      const starsGeometry = new THREE.SphereGeometry(500, 32, 32); 
      const starsMap = this.loadTexture(starsTexture);
      const starsMaterial = new THREE.MeshBasicMaterial({
        map: starsMap,
        side: THREE.BackSide,
        transparent: true,
        opacity: 0.3
      });
      const starsSphere = new THREE.Mesh(starsGeometry, starsMaterial);
      this.scene.add(starsSphere);

      // Setup camera
      this.camera = new THREE.PerspectiveCamera(
        60,  // Reduced FOV for tighter zoom
        window.innerWidth / window.innerHeight,
        0.01,  // Reduced near plane for closer objects
        100    // Reduced far plane to match scale
      );

      // Use first keyframe for initial position
      const firstKeyframe = this.cameraKeyframes[0];
      this.camera.position.set(firstKeyframe.position.x, firstKeyframe.position.y, firstKeyframe.position.z);
      this.camera.lookAt(firstKeyframe.lookAt.x, firstKeyframe.lookAt.y, firstKeyframe.lookAt.z);

      // Setup renderer
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.$refs.container.appendChild(this.renderer.domElement);

      // Sunlight
      const sunLight = new THREE.PointLight(0xffffff, 2, 30);  // reduced intensity from 20 to 2, distance from 300 to 30
      sunLight.position.set(0, 0, 0);
      this.scene.add(sunLight);

      // Add ambient light to provide general illumination
      const ambientLight = new THREE.AmbientLight(0x404040, 0.2); // reduced from 0.3 to 0.2
      this.scene.add(ambientLight);

      // Sun
      const sunGeometry = new THREE.SphereGeometry(this.objectsize.sun, 32, 32); 
      const sunMap = this.loadTexture(sunTexture);
      const sunMaterial = new THREE.MeshBasicMaterial({
        map: sunMap,
        color: 0xffff80,
        opacity: 0.6,     // reduced from 0.8 to 0.6
        transparent: true
      });
      this.sun = new THREE.Mesh(sunGeometry, sunMaterial);
      this.sun.position.x = 0; // Center position
      this.scene.add(this.sun);

      // Mercury (0.383 Earth radii)
      const mercuryGeometry = new THREE.SphereGeometry(this.objectsize.mercury, 32, 32);
      const mercuryMap = this.loadTexture(mercuryTexture);
      const mercuryMaterial = new THREE.MeshPhongMaterial({ 
        map: mercuryMap,
        shininess: 5
      });
      this.mercury = new THREE.Mesh(mercuryGeometry, mercuryMaterial);
      this.scene.add(this.mercury);

      // Venus (0.949 Earth radii)
      const venusGeometry = new THREE.SphereGeometry(this.objectsize.venus, 32, 32);
      const venusMap = this.loadTexture(venusTexture);
      const venusMaterial = new THREE.MeshPhongMaterial({ 
        map: venusMap,
        shininess: 5
      });
      this.venus = new THREE.Mesh(venusGeometry, venusMaterial);
      this.venus.position.x = -6;
      this.scene.add(this.venus);

      // Earth
      const earthGeometry = new THREE.SphereGeometry(this.objectsize.earth, 32, 32);
      const earthMap = this.loadTexture(earthTexture);
      const earthMaterial = new THREE.MeshPhongMaterial({ 
        map: earthMap,
        shininess: 5
      });
      this.earth = new THREE.Mesh(earthGeometry, earthMaterial);
      this.earth.position.x = -8;
      this.scene.add(this.earth);

      // Cloud layer
      const cloudGeometry = new THREE.SphereGeometry(this.objectsize.earth, 32, 32);
      const cloudMap = this.loadTexture(cloudsTexture);
      const cloudMaterial = new THREE.MeshPhongMaterial({
        map: cloudMap,
        transparent: true,
        opacity: 0.8,
        alphaMap: cloudMap, // Uses the texture as alpha map (black parts become transparent)
        depthWrite: false // Prevents z-fighting with the earth surface
      });
      this.clouds = new THREE.Mesh(cloudGeometry, cloudMaterial);
      this.earth.add(this.clouds); // Add clouds as a child of earth so they rotate together

      // Mars
      const marsGeometry = new THREE.SphereGeometry(this.objectsize.mars, 32, 32); 
      const marsMap = this.loadTexture(marsTexture);
      const marsMaterial = new THREE.MeshPhongMaterial({ 
        map: marsMap,
        shininess: 5
      });
      this.mars = new THREE.Mesh(marsGeometry, marsMaterial);
      this.mars.position.x = 8;
      this.scene.add(this.mars);

      // Jupiter (11.209 Earth radii)
      const jupiterGeometry = new THREE.SphereGeometry(this.objectsize.jupiter, 32, 32);
      const jupiterMap = this.loadTexture(jupiterTexture);
      const jupiterMaterial = new THREE.MeshPhongMaterial({ 
        map: jupiterMap,
        shininess: 5
      });
      this.jupiter = new THREE.Mesh(jupiterGeometry, jupiterMaterial);
      this.jupiter.position.x = 12;
      this.scene.add(this.jupiter);

      // Saturn (9.449 Earth radii)
      const saturnGeometry = new THREE.SphereGeometry(this.objectsize.saturn, 32, 32);
      const saturnMap = this.loadTexture(saturnTexture);
      const saturnMaterial = new THREE.MeshPhongMaterial({ 
        map: saturnMap,
        shininess: 5
      });
      this.saturn = new THREE.Mesh(saturnGeometry, saturnMaterial);
      this.saturn.position.x = 16;
      this.scene.add(this.saturn);

      // Uranus (4.007 Earth radii)
      const uranusGeometry = new THREE.SphereGeometry(this.objectsize.uranus, 32, 32);
      const uranusMap = this.loadTexture(uranusTexture);
      const uranusMaterial = new THREE.MeshPhongMaterial({ 
        map: uranusMap,
        shininess: 5
      });
      this.uranus = new THREE.Mesh(uranusGeometry, uranusMaterial);
      this.uranus.position.x = 20;
      this.scene.add(this.uranus);

      // Neptune (3.883 Earth radii)
      const neptuneGeometry = new THREE.SphereGeometry(this.objectsize.neptune, 32, 32);
      const neptuneMap = this.loadTexture(neptuneTexture);
      const neptuneMaterial = new THREE.MeshPhongMaterial({ 
        map: neptuneMap,
        shininess: 5
      });
      this.neptune = new THREE.Mesh(neptuneGeometry, neptuneMaterial);
      this.neptune.position.x = 24;
      this.scene.add(this.neptune);

      // Handle window resize
      window.addEventListener('resize', this.onWindowResize);

      // Setup post-processing
      this.composer = new EffectComposer(this.renderer);
      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      this.bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.5,    // Bloom strength
        0.75,   // Radius
        0.4     // Threshold
      );
      this.composer.addPass(this.bloomPass);

      // Moon
      const moonGeometry = new THREE.SphereGeometry(this.objectsize.moon, 32, 32);
      const moonMap = this.loadTexture(moonTexture);
      const moonMaterial = new THREE.MeshPhongMaterial({
        map: moonMap,
        shininess: 5
      });

      // Create a pivot point for the moon's orbit
      this.moonPivot = new THREE.Object3D();
      this.earth.add(this.moonPivot);

      this.moon = new THREE.Mesh(moonGeometry, moonMaterial);
      // Reduce the orbit distance (was 0.7)
      this.moon.position.x = 0.08; // Much closer to Earth
      // Add some offset to create a slight tilt in the orbit
      this.moon.position.y = 0.01;
      this.moonPivot.add(this.moon);

      // Add a slight tilt to the moon's orbit
      this.moonPivot.rotation.x = 0.1; // Tilt the orbit plane slightly

      // Load Voyager 1 model
      const gltfLoader = new GLTFLoader();
      try {
        const voyagerModel = await gltfLoader.loadAsync('/data/voyager_12.glb');
        this.voyager = voyagerModel.scene;
        this.voyager.scale.set(this.objectsize.voyager, this.objectsize.voyager, this.objectsize.voyager); // Adjust scale as needed
        this.scene.add(this.voyager);
      } catch (error) {
        console.error('Error loading Voyager 1 model:', error);
      }

      // Re-enable OrbitControls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;
      this.controls.enablePan = true;
      this.controls.enableZoom = true;
      this.controls.minDistance = 0.5;
      this.controls.maxDistance = 10;

      // Add these event listeners to detect user interaction
      this.controls.addEventListener('start', () => {
        this.userHasAdjustedCamera = true;
      });

      // Create point lights for outer planets
      const createPlanetLight = (color = 0xffffff, intensity = 0.5, distance = 10) => {
        const light = new THREE.PointLight(color, intensity, distance);
        light.castShadow = false;
        return light;
      };

      // Add lights as children to the planets
      if (this.jupiter) {
        const jupiterLight = createPlanetLight(0xffffff, 0.5, 15);
        this.jupiter.add(jupiterLight);
      }

      if (this.saturn) {
        const saturnLight = createPlanetLight(0xffffff, 0.5, 15);
        this.saturn.add(saturnLight);
      }

      if (this.uranus) {
        const uranusLight = createPlanetLight(0xffffff, 0.5, 15);
        this.uranus.add(uranusLight);
      }

      if (this.neptune) {
        const neptuneLight = createPlanetLight(0xffffff, 0.5, 15);
        this.neptune.add(neptuneLight);
      }

      // // Add a light that follows Voyager
      // if (this.voyager) {
      //   const voyagerLight = createPlanetLight(0xffffff, 3, 20);
      //   this.voyager.add(voyagerLight);
      // }
    },
    handleScroll() {
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      
      this.isScrolling = true;
      
      // Calculate single source of truth for scroll position
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      this.currentScrollPosition = window.pageYOffset / totalHeight;
      
      // Add console logging for scroll position and current date
      console.log(`Scroll Position: ${(this.currentScrollPosition * 100).toFixed(2)}%`);
      console.log(`Timeline Date: ${this.currentDate}`);
      
      // Find and log current milestone
      const currentMilestone = this.milestones.find((milestone, index) => {
        const nextMilestone = this.milestones[index + 1];
        return this.currentScrollPosition >= milestone.scrollPosition && 
               (!nextMilestone || this.currentScrollPosition < nextMilestone.scrollPosition);
      });
      
      if (currentMilestone) {
        console.log('Current Milestone:', {
          date: currentMilestone.date,
          content: currentMilestone.content.substring(0, 50) + '...',
          scrollPosition: (currentMilestone.scrollPosition * 100).toFixed(2) + '%',
          timelineDate: this.currentDate
        });
      }
      
      // Update content container scroll position
      const container = this.$el.querySelector('.content-container');
      if (container && !this.contentScrolling) {
        const contentHeight = container.scrollHeight - container.clientHeight;
        container.scrollTop = this.currentScrollPosition * contentHeight;
      }
      
      // Update trajectory data based on scroll position
      const index = Math.floor(this.currentScrollPosition * (this.trajectoryData.length - 1));
      if (this.trajectoryData[index]) {
        this.currentDate = this.trajectoryData[index].date;
        this.updatePlanetPositions(index);
        this.updateCameraPosition(index);
      }
      
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 150);
    },
    animate() {
      if (this.isDestroyed) return;
      
      requestAnimationFrame(this.animate);
      
      // Only update if visible
      if (document.hidden) return;

      // Update planet positions smoothly
      this.updatePlanetMotion();

      // Rest of your animate method...
      if (this.earth) {
        this.earth.rotation.y += 0.0005;
      }
      if (this.sun) {
        this.sun.rotation.y += 0.0003; 
      }
      if (this.mars) {
        this.mars.rotation.y += 0.0004;
      }

      if (this.clouds) {
        this.clouds.rotation.y += 0.0007; // Slightly faster than Earth for cloud movement
      }

      if (this.moonPivot) {
        this.moonPivot.rotation.y += 0.015; // Slower orbit (was 0.002)
      }
      if (this.moon) {
        this.moon.rotation.y += 0.005; // Slower self-rotation (was 0.001)
      }

      this.composer.render();
    },
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.composer.setSize(window.innerWidth, window.innerHeight);
    },
    scrollTo(position) {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const targetScroll = position * totalHeight;
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
    },
    scaleCoordinates(x, y, z) {
      // Calculate radial distance
      const r = Math.sqrt(x * x + y * y + z * z);
      
      // Avoid log(0) - use small epsilon value
      const radius = r > 0 ? r : 1e-10;
      
      // Scale factor based on log of radius
      const scale = Math.log10(radius + 1) / (radius + 1e-10);
      
      // Apply scaling while preserving direction
      return {
        x: x * scale,
        y: y * scale,
        z: z * scale
      };
    },
    async loadTrajectoryData() {
      console.log('Loading trajectory data...');
      try {
        const response = await fetch('/data/unified_trajectories.json');
        const json = await response.json();
        this.json = json; // Store the full JSON data
        const trajectoryJson = json.bodies;
        
        this.trajectoryData = [];
        
        const dates = trajectoryJson.mercury.trajectory_data.map(point => point.date);
        
        // Base scale factor (can be adjusted)
        const baseFactor = 10;
        
        dates.forEach((date, index) => {
          const dataPoint = { date };
          
          // Add positions for each celestial body
          ['mercury', 'venus', 'earth', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune'].forEach(body => {
            if (trajectoryJson[body] && trajectoryJson[body].trajectory_data[index]) {
              const bodyData = trajectoryJson[body].trajectory_data[index];
              
              // Apply logarithmic scaling
              const scaledPosition = this.scaleCoordinates(
                bodyData.x * baseFactor,
                bodyData.y * baseFactor,
                bodyData.z * baseFactor
              );
              
              dataPoint[`${body}_x`] = scaledPosition.x;
              dataPoint[`${body}_y`] = scaledPosition.y;
              dataPoint[`${body}_z`] = scaledPosition.z;
            }
          });

          // Handle Voyager separately
          if (trajectoryJson.voyager1 && trajectoryJson.voyager1.trajectory_data[index]) {
            const voyagerData = trajectoryJson.voyager1.trajectory_data[index];
            
            // Apply logarithmic scaling to Voyager
            const scaledPosition = this.scaleCoordinates(
              voyagerData.x * baseFactor,
              voyagerData.y * baseFactor,
              voyagerData.z * baseFactor
            );
            
            dataPoint.voyager1_x = scaledPosition.x;
            dataPoint.voyager1_y = scaledPosition.y;
            dataPoint.voyager1_z = scaledPosition.z;
          }
          
          this.trajectoryData.push(dataPoint);
        });

        // Update timeline years generation
        // Get actual start and end dates from trajectory data
        const startDate = new Date(this.trajectoryData[0].date);
        const endDate = new Date(this.trajectoryData[this.trajectoryData.length - 1].date);
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const totalYears = endYear - startYear;
        
        // Calculate step size to get approximately 8-10 markers
        const yearStep = Math.max(1, Math.ceil(totalYears / 8));
        
        // Generate array of years within the actual data range
        this.timelineYears = [];
        for (let year = startYear; year <= endYear; year += yearStep) {
          this.timelineYears.push(year);
        }
        // Ensure the end year is included if it's not already
        if (this.timelineYears[this.timelineYears.length - 1] !== endYear) {
          this.timelineYears.push(endYear);
        }

        if (this.trajectoryData.length > 0) {
          this.currentDate = this.trajectoryData[0].date;
        }

        console.log('Loaded trajectory data points:', this.trajectoryData.length);
        console.log('Timeline years:', this.timelineYears);
        this.updatePlanetPositions(0);
        
      } catch (error) {
        console.error('Error loading trajectory data:', error);
      }
    },
    updatePlanetPositions(dataIndex) {
      if (!this.trajectoryData.length || dataIndex >= this.trajectoryData.length) {
        return;
      }

      const currentData = this.trajectoryData[dataIndex];
      const currentDate = new Date(currentData.date);
      const baseFactor = 10; // Same scale factor as used in loadTrajectoryData

      // For each planet, find the closest data points before and after the current date
      ['mercury', 'venus', 'earth', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune'].forEach(planet => {
        const planetData = this.json.bodies[planet].trajectory_data;
        
        // Find the bracketing data points
        let beforeIndex = 0;
        let afterIndex = 1;
        
        for (let i = 0; i < planetData.length - 1; i++) {
          const date1 = new Date(planetData[i].date);
          const date2 = new Date(planetData[i + 1].date);
          
          if (currentDate >= date1 && currentDate <= date2) {
            beforeIndex = i;
            afterIndex = i + 1;
            break;
          }
        }

        // Calculate interpolation factor based on dates
        const beforeDate = new Date(planetData[beforeIndex].date);
        const afterDate = new Date(planetData[afterIndex].date);
        const factor = (currentDate - beforeDate) / (afterDate - beforeDate);

        // Interpolate between the two positions
        const before = planetData[beforeIndex];
        const after = planetData[afterIndex];
        
        // Interpolate raw positions first
        const interpolatedPos = {
          x: this.lerp(before.x, after.x, factor) * baseFactor,
          y: this.lerp(before.y, after.y, factor) * baseFactor,
          z: this.lerp(before.z, after.z, factor) * baseFactor
        };

        // Then apply logarithmic scaling
        const scaledPos = this.scaleCoordinates(
          interpolatedPos.x,
          interpolatedPos.y,
          interpolatedPos.z
        );
        
        this.planetTargets[planet].set(scaledPos.x, scaledPos.y, scaledPos.z);
      });

      // Handle Voyager with the same scaling
      if (this.json.bodies.voyager1) {
        const voyagerData = this.json.bodies.voyager1.trajectory_data;
        let beforeIndex = 0;
        let afterIndex = 1;
        
        // Find the bracketing data points
        for (let i = 0; i < voyagerData.length - 1; i++) {
          const date1 = new Date(voyagerData[i].date);
          const date2 = new Date(voyagerData[i + 1].date);
          
          if (currentDate >= date1 && currentDate <= date2) {
            beforeIndex = i;
            afterIndex = i + 1;
            break;
          }
        }

        const beforeDate = new Date(voyagerData[beforeIndex].date);
        const afterDate = new Date(voyagerData[afterIndex].date);
        const factor = (currentDate - beforeDate) / (afterDate - beforeDate);

        const before = voyagerData[beforeIndex];
        const after = voyagerData[afterIndex];
        
        const interpolatedPos = {
          x: this.lerp(before.x, after.x, factor) * baseFactor,
          y: this.lerp(before.y, after.y, factor) * baseFactor,
          z: this.lerp(before.z, after.z, factor) * baseFactor
        };

        const scaledPos = this.scaleCoordinates(
          interpolatedPos.x,
          interpolatedPos.y,
          interpolatedPos.z
        );
        
        this.planetTargets.voyager1.set(scaledPos.x, scaledPos.y, scaledPos.z);
      }
    },
    createCircularInterpolation(currentPos, targetPos, factor) {
      // Get the current radius and angle
      const currentRadius = Math.sqrt(
        currentPos.x * currentPos.x + 
        currentPos.y * currentPos.y
      );
      const currentAngle = Math.atan2(currentPos.y, currentPos.x);

      // Get the target radius and angle
      const targetRadius = Math.sqrt(
        targetPos.x * targetPos.x + 
        targetPos.y * targetPos.y
      );
      const targetAngle = Math.atan2(targetPos.y, targetPos.x);

      // Interpolate radius and angle
      let angleDiff = targetAngle - currentAngle;
      
      // Handle angle wrapping
      if (angleDiff > Math.PI) angleDiff -= Math.PI * 2;
      if (angleDiff < -Math.PI) angleDiff += Math.PI * 2;
      
      const newRadius = currentRadius + (targetRadius - currentRadius) * factor;
      const newAngle = currentAngle + angleDiff * factor;

      // Convert back to Cartesian coordinates
      return new THREE.Vector3(
        newRadius * Math.cos(newAngle),
        newRadius * Math.sin(newAngle),
        currentPos.z + (targetPos.z - currentPos.z) * factor
      );
    },
    updatePlanetMotion() {
      const innerPlanets = ['mercury', 'venus', 'earth', 'mars'];
      const outerPlanets = ['jupiter', 'saturn', 'uranus', 'neptune'];

      // Inner planets use circular interpolation
      innerPlanets.forEach(planet => {
        if (this[planet] && this.planetTargets[planet]) {
          const newPos = this.createCircularInterpolation(
            this[planet].position,
            this.planetTargets[planet],
            this.lerpFactor
          );
          this[planet].position.copy(newPos);
        }
      });

      // Outer planets and other objects use regular lerp
      outerPlanets.forEach(planet => {
        if (this[planet]) {
          this[planet].position.lerp(this.planetTargets[planet], this.lerpFactor);
        }
      });

      // Handle special objects
      if (this.voyager) {
        this.voyager.position.lerp(this.planetTargets.voyager1, this.lerpFactor);
      }
    },
    scrollToYear(year) {
      const index = this.trajectoryData.findIndex(
        point => new Date(point.date).getFullYear() === year
      );
      if (index !== -1) {
        const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPosition = (index / (this.trajectoryData.length - 1)) * totalHeight;
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    },
    isCurrentYear(year) {
      return year === this.activeTimelineYear;
    },
    updateCameraPosition(dataIndex) {
      // Skip camera updates if user has manually adjusted the view
      if (this.userHasAdjustedCamera || this.debugFreeCam) return;
      
      if (!this.trajectoryData[dataIndex]) return;
      
      const currentDate = new Date(this.trajectoryData[dataIndex].date);
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      
      // Find the two keyframes we're between
      let startFrame = this.cameraKeyframes[0];
      let endFrame = this.cameraKeyframes[this.cameraKeyframes.length - 1];
      
      for (let i = 0; i < this.cameraKeyframes.length - 1; i++) {
        if (currentYear >= this.cameraKeyframes[i].year && 
            currentYear < this.cameraKeyframes[i + 1].year) {
          startFrame = this.cameraKeyframes[i];
          endFrame = this.cameraKeyframes[i + 1];
          break;
        }
      }
      
      // Calculate interpolation factor including months for smoother transition
      const yearProgress = (currentYear + currentMonth / 12 - startFrame.year) / 
                          (endFrame.year - startFrame.year);
      
      // Apply easing function (this creates a smooth acceleration and deceleration)
      const easeProgress = this.easeInOutCubic(Math.max(0, Math.min(1, yearProgress)));
      
      // Interpolate camera position
      const newPosition = {
        x: this.lerp(startFrame.position.x, endFrame.position.x, easeProgress),
        y: this.lerp(startFrame.position.y, endFrame.position.y, easeProgress),
        z: this.lerp(startFrame.position.z, endFrame.position.z, easeProgress)
      };
      
      // Interpolate lookAt position
      const newLookAt = {
        x: this.lerp(startFrame.lookAt.x, endFrame.lookAt.x, easeProgress),
        y: this.lerp(startFrame.lookAt.y, endFrame.lookAt.y, easeProgress),
        z: this.lerp(startFrame.lookAt.z, endFrame.lookAt.z, easeProgress)
      };
      
      // Update camera position and lookAt with lerping
      this.camera.position.lerp(new THREE.Vector3(newPosition.x, newPosition.y, newPosition.z), 0.1);
      
      // Create and update the lookAt target with lerping
      const lookAtTarget = new THREE.Vector3(newLookAt.x, newLookAt.y, newLookAt.z);
      this.camera.lookAt(lookAtTarget);
    },
    lerp(start, end, t) {
      return start * (1 - t) + end * t;
    },
    easeInOutCubic(t) {
      return t < 0.5
        ? 4 * t * t * t
        : 1 - Math.pow(-2 * t + 2, 3) / 2;
    },
    loadTexture(url) {
      if (this.loadedTextures.has(url)) {
        return this.loadedTextures.get(url);
      }
      
      const texture = new THREE.TextureLoader().load(url);
      this.loadedTextures.set(url, texture);
      return texture;
    },
    setupKeyboardListeners() {
      if (!this.camera) {
        console.warn('Camera not initialized, skipping keyboard listeners');
        return;
      }

      // Store the handler function reference
      this.keyboardHandler = (e) => {
        // Camera position logging
        if (e.key === 'p') {
          if (this.camera && !this.isDestroyed) {
            try {
              // Create a new vector only if camera exists
              const cameraDirection = new THREE.Vector3();
              const lookAtTarget = this.camera.getWorldDirection(cameraDirection);
              
              // Calculate position relative to camera
              const position = this.camera.position.clone();
              const target = position.clone().add(cameraDirection.multiplyScalar(10));

              console.log('Camera Debug:', {
                position: {
                  x: Number(position.x.toFixed(2)),
                  y: Number(position.y.toFixed(2)),
                  z: Number(position.z.toFixed(2))
                },
                lookAt: {
                  x: Number(target.x.toFixed(2)),
                  y: Number(target.y.toFixed(2)),
                  z: Number(target.z.toFixed(2))
                },
                rotation: {
                  x: Number(this.camera.rotation.x.toFixed(2)),
                  y: Number(this.camera.rotation.y.toFixed(2)),
                  z: Number(this.camera.rotation.z.toFixed(2))
                },
                fov: this.camera.fov,
                aspect: Number(this.camera.aspect.toFixed(2))
              });
            } catch (error) {
              console.warn('Error logging camera debug info:', error);
            }
          }
        }

        // Memory info logging
        if (e.key === 'm') {
          if (this.renderer && !this.isDestroyed) {
            try {
              console.log('Memory Info:', {
                geometries: this.renderer.info.memory.geometries,
                textures: this.renderer.info.memory.textures,
                programs: this.renderer.info.programs?.length || 0
              });
            } catch (error) {
              console.warn('Renderer not ready for memory logging:', error);
            }
          }
        }

        // Add new handler for toggling free camera mode (using 'f' key)
        if (e.key === 'f') {
          this.debugFreeCam = !this.debugFreeCam;
          console.log('Free camera mode:', this.debugFreeCam ? 'ON' : 'OFF');
          
          // Add these lines to verify the controls are working
          if (this.debugFreeCam) {
            this.controls.enabled = true;
          }
        }
      };

      // Add the event listener using the stored handler
      window.addEventListener('keydown', this.keyboardHandler);
    },
    resetCameraToAutomatic() {
      this.userHasAdjustedCamera = false;
      // Reset to current keyframe position
      const dataIndex = this.currentDataIndex;
      this.updateCameraPosition(dataIndex);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    handleContentScroll(event) {
      const container = event.target;
      const scrollPercent = container.scrollTop / (container.scrollHeight - container.clientHeight);
      
      if (!this.contentScrolling) {
        this.contentScrolling = true;
        
        // Update the single source of truth
        this.currentScrollPosition = scrollPercent;
        
        // Update main page scroll
        const totalPageScroll = document.documentElement.scrollHeight - window.innerHeight;
        window.scrollTo(0, totalPageScroll * scrollPercent);
        
        setTimeout(() => {
          this.contentScrolling = false;
        }, 50);
      }
    }
  }
};
</script>

<style>
/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&family=Roboto+Mono:wght@400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll; 
  height: 100%;
  font-family: 'Roboto Mono', monospace;
  color: white;
}

html {
  height: 100%;
}

/* Component styles */
.scene-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.scroll-container {
  height: 2000vh;
  width: 100%;
  position: relative;
  z-index: 0;
  pointer-events: none;
}

/* Logo styles */
.logo {
  position: fixed;
  top: 20px;
  left: 15px;
  z-index: 2;
  width: 80px; /* Adjust size as needed */
  height: auto;
}

/* Header content */
.header-content {
  position: relative;
  width: 100%;
  padding: 30vh 0 1800vh 0;  /* Adjusted to leave room for header while matching total height */
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 20px;
}

.description {
  font-family: 'Roboto Mono', monospace;
  opacity: 0.5;
  line-height: 1.6;
  margin-bottom: 40px;
}

.milestones-title {
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  margin-bottom: 20px;
  font-weight: 400;
}

/* Timeline styles */
.timeline {
  position: fixed;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-years {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}

.timeline-marker.year {
  font-family: 'Roboto Mono', monospace;
  color: white;
  cursor: pointer;
  padding: 1px 12px;
  font-size: 14px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: left;
  gap: 8px;
  justify-content: flex-start;
  text-align: left;
}

.timeline-marker.empty-year {
  cursor: default;
  padding: 1px 12px;
  opacity: 0.25;
  text-align: left;
  transition: opacity 0.3s ease-in-out;
}

.timeline-marker.year:hover:not(.empty-year) {
  opacity: 0.8;
}

.timeline-marker.year.active {
  opacity: 1;
  font-weight: 500;
}

/* Navigation menu */
.nav-menu {
  position: fixed;
  top: 20px;
  right: 40px;
  z-index: 2;
  display: flex;
  gap: 40px;
}

.nav-item {
  font-family: 'Roboto Mono', monospace;
  color: white;
  opacity: 0.5;
  text-decoration: none;
  transition: opacity 0.3s;
}

.nav-item:hover {
  opacity: 1;
}

.nav-item.active {
  opacity: 1;
}

/* Update content container styles */
.content-container {
  position: fixed;
  top: 0;
  left: 100px;
  height: 2000vh;  /* Match scroll-container */
  width: 500px;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0) 0%,
    rgba(17, 17, 17, 1) 30%,
    rgba(17, 17, 17, 1) 70%,
    rgba(17, 17, 17, 0) 100%
  );
  border-left: 1px solid #575760;
  border-right: 1px solid #575760;
  padding: 0 40px;
  box-sizing: border-box;
  overflow-y: auto;
  pointer-events: auto;
}

.content-container.mobile {
  left: 0;
  width: 100%;
  padding: 0 20px;
  border: none;
}

/* Timeline styles */
.timeline {
  position: fixed;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline.mobile {
  right: 20px;
  bottom: 40px;
  top: auto;
  transform: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-content {
    padding: 25vh 20px 100vh 20px;
  }

  .title {
    font-size: 42px;
    margin-bottom: 15px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 60px;
  }

  .milestone {
    margin-bottom: 30px;
    padding: 15px;
  }

  .milestone-date {
    font-family: 'Roboto Mono', monospace;
    opacity: 1;
    line-height: 1.6;
    margin-bottom: 40px;
  }

  .milestone-content {
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    line-height: 1.6;
    margin-bottom: 40px;
  }

  .timeline-years {
    transform: scale(0.8);
    transform-origin: right bottom;
  }

  .timeline-marker.year {
    font-size: 12px;
    padding: 1px 8px;
  }
}

/* Remove these styles as they're no longer needed */
.mobile-content,
.mobile-header,
.mobile-timeline-container,
.mobile-milestones {
  display: none;
}

.milestones {
  position: relative;
  height: 2000vh; /* Match scroll-container height */
  width: 100%;
}

.milestone {
  position: absolute;
  width: 100%;
  padding: 20px 0;
}

.milestone-date {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  opacity: 1;
}

.milestone-content {
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  line-height: 1.6;
  opacity: 0.5;
}
</style> 