import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import SpaceExploration from '@/components/articles/SpaceExploration.vue'
import Placeholder2 from '@/components/articles/Placeholder2.vue'
import Placeholder3 from '@/components/articles/Placeholder3.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/article/space-exploration',
    name: 'SpaceExploration',
    component: SpaceExploration
  },
  {
    path: '/article/placeholder-2',
    name: 'Placeholder2',
    component: Placeholder2
  },
  {
    path: '/article/placeholder-3',
    name: 'Placeholder3',
    component: Placeholder3
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
