import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/main.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3AOoCAoyiehCfxO_Xm1mqgoxGharuacA",
  authDomain: "axis-studio-ed7a0.firebaseapp.com",
  projectId: "axis-studio-ed7a0",
  storageBucket: "axis-studio-ed7a0.firebasestorage.app",
  messagingSenderId: "1032842002651",
  appId: "1:1032842002651:web:5f3f80916b01e68c870cfe",
  measurementId: "G-GYBYMQTXPF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.config.productionTip = false
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
