var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"container",staticClass:"scene-container"}),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/axisStudioLogo_White.png"),"alt":"Axis Studio"}}),_c('nav',{staticClass:"nav-menu",class:{ 'mobile': _vm.isMobile }},[_c('a',{staticClass:"nav-item",attrs:{"href":"#"}},[_vm._v("PAST MISSIONS")]),_c('a',{staticClass:"nav-item active",attrs:{"href":"#"}},[_vm._v("CURRENT MISSIONS")]),_c('a',{staticClass:"nav-item",attrs:{"href":"#"}},[_vm._v("FUTURE MISSIONS")])]),(!_vm.isMobile)?_c('div',{staticClass:"content-container",on:{"scroll":_vm.handleContentScroll}},[_c('div',{staticClass:"header-content"},[_c('h1',{staticClass:"title"},[_vm._v("Voyager")]),_c('p',{staticClass:"description"},[_vm._v(" Voyager 1 and Voyager 2 are currently the furthest spacecraft from earth. They were both launched in summer of 1977 taking advantage of a rare planetary alignment that would enable them to get first ever images of several planets, their moons, as well as slingshot out of the solar system. ")]),_c('div',{staticClass:"milestones"},_vm._l((_vm.milestones),function(milestone,index){return _c('div',{key:index,staticClass:"milestone",style:({ 
              position: 'absolute',
              top: `${milestone.scrollPosition * 100}%`
             })},[_c('div',{staticClass:"milestone-date"},[_vm._v(_vm._s(_vm.formatDate(milestone.date)))]),_c('div',{staticClass:"milestone-content"},[_vm._v(_vm._s(milestone.content))])])}),0)])]):_c('div',{staticClass:"content-container mobile",on:{"scroll":_vm.handleContentScroll}},[_c('div',{staticClass:"header-content"},[_c('h1',{staticClass:"title"},[_vm._v("Voyager")]),_c('p',{staticClass:"description"},[_vm._v(" Voyager 1 and Voyager 2 are currently the furthest spacecraft from earth. They were both launched in summer of 1977 taking advantage of a rare planetary alignment that would enable them to get first ever images of several planets, their moons, as well as slingshot out of the solar system. ")]),_c('div',{staticClass:"milestones"},_vm._l((_vm.milestones),function(milestone,index){return _c('div',{key:index,staticClass:"milestone",style:({ 
              position: 'absolute',
              top: `${milestone.scrollPosition * 100}%`
             })},[_c('div',{staticClass:"milestone-date"},[_vm._v(_vm._s(_vm.formatDate(milestone.date)))]),_c('div',{staticClass:"milestone-content"},[_vm._v(_vm._s(milestone.content))])])}),0)])]),_c('div',{staticClass:"timeline",class:{ 'mobile': _vm.isMobile }},[_c('div',{staticClass:"timeline-years"},[_vm._l((_vm.timelineYears),function(year,index){return [_c('div',{key:`year-${year}-${index}`,staticClass:"timeline-marker year",class:{ active: _vm.isCurrentYear(year) },on:{"click":function($event){return _vm.scrollToYear(year)}}},[_vm._v(" — "+_vm._s(year)+" ")]),(index < _vm.timelineYears.length - 1)?_c('div',{key:`dash-${year}-${index}`,staticClass:"timeline-marker year empty-year"},[_vm._v(" — ")]):_vm._e()]})],2)]),_c('div',{ref:"scrollContainer",staticClass:"scroll-container"},[_c('div',{staticClass:"scroll-debug"},[_vm._v("Current Date: "+_vm._s(_vm.currentDate))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }